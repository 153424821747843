<template>
    <div>
        <particles />
        <van-tabs v-model="active" v-if="Tshow">
            <van-tab title="基础测评报告">
                <div class="text_container" :style="{ width: `${container_width}px`,height:`${container_height}px`}">
                    <van-cell-group inset class="mtb10">
                        <van-cell v-for="(item, index) in lists.main" :key="index" :class="index%2 ==0?'jishu':'oushu'" title="基础测评报告" :label="'测评时间：' + item.create_time.replace('T', ' ')" is-link @click="goreport(item.id)" />
                    </van-cell-group>
                </div>
            </van-tab>
            <van-tab title="单科测评报告">
                <div class="text_container" :style="{ width: `${container_width}px`,height:`${container_height}px`}">
                    <van-field v-model="fieldValue" is-link readonly label="病症：" placeholder="请选择要查询的病症" @click="show=true" />
                    <van-popup v-model:show="show" round position="bottom">
                        <van-cascader v-model="cascaderValue" title="请选择要查询的病症" :options="options" @close="show = false" @finish="onFinish" />
                    </van-popup>
                    <van-cell-group inset class="mtb10" v-if="list.length != 0">
                        <van-cell v-for="(item, index) in list" :key="index" :class="index%2 ==0?'jishu':'oushu'" :title="value1+'测评报告'" :label="'测评时间：' + item.create_time.replace('T', ' ')" is-link @click="gosingle(item.id)" />
                    </van-cell-group>

                    <van-empty v-else description="请选择要查询的病症" />
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import particles from "../components/particles.vue";

export default {
    name: "",
    props: {},
    components: {
        particles,
    },
    data() {
        return {
            container_height: 0,
            container_width: 0,
            background_text: null,
            disease_chart: null,
            health_chart: null,
            lists: {},
            list: [],
            value1: "",
            show: false,
            fieldValue: "",
            cascaderValue: "",
            // 选项列表，children 代表子选项，支持多级嵌套
            options: [],
            Tshow: false,
        };
    },
    methods: {
        // 全部选项选择完毕后，会触发 finish 事件
        onFinish({ selectedOptions }) {
            this.show = false;
            this.fieldValue = selectedOptions
                .map((option) => option.text)
                .join("/");
            for (let key in this.lists.single_subject) {
                if (key == selectedOptions[0].text) {
                    this.value1 = selectedOptions[0].text;
                    this.list = this.lists.single_subject[key];
                }
            }
        },
        tidyup() {
            for (let key in this.lists.single_subject) {
                if (this.lists.single_subject[key].length !== 0) {
                    this.options.push({
                        text: key,
                        value: key,
                    });
                }
            }
        },
        gosingle(id) {
            let questionnaire_report_id = id;
            this.$router.push({
                path: "/single",
                query: {
                    questionnaire_report_id,
                },
            });
        },
        goreport(id) {
            let report_id = id;
            this.$router.push({
                path: "/report",
                query: {
                    report_id,
                },
            });
        },
        getList() {
            this.$toast.loading({
                duration: 0,
                message: "请稍等...",
                forbidClick: true,
            });
            this.$api("getHistoryReportList").then((res) => {
                this.lists = res;
                this.tidyup();
            });
        },
    },
    watch: {
        lists(val) {
            if (JSON.stringify(val) !== "{}") {
                this.Tshow = true;
                this.$toast.clear();
            }
        },
    },
    mounted() {
        window.onresize = () => {
            this.container_height = document.documentElement.clientHeight * 0.9;
            let container_width = document.body.clientWidth * 0.85;
            let brain_width = container_width * 0.2;
            this.brain_width = brain_width;
            // 子元素按父元素比例动态计算宽度，children与parent传值：[min, max]
            // parent：两个临界点父元素宽度，传值格式：[min, max]
            // children：与两个临界点对应的子元素宽度，传值格式：[min, max]
            // min_calc_rule：小于最小宽度后的子元素变化规则：y = (x + b1) * k + b2，传值格式：[b1, k, b2]，默认值
            let calc = (
                children,
                parent,
                min_calc_rule,
                width = document.body.clientWidth
            ) => {
                // 大于设定最大宽度，返回子元素最大宽度
                if (width > parent[1]) return children[1];
                // 小于设定最小宽度，按计算公式返回子元素宽度，且当width = patent[0]时，计算值与children[0]相等
                if (width < parent[0])
                    return (
                        (width + min_calc_rule[0]) * min_calc_rule[1] +
                        min_calc_rule[2]
                    );
                // 介于二者之间，按比例变化
                return (
                    ((width - parent[0]) / (parent[1] - parent[0])) *
                        (children[1] - children[0]) +
                    children[0]
                );
            };
            this.container_width = calc([920, 960], [960, 1280], [-40, 1, 0]);
            this.brain_width = calc(
                [552, 576],
                [920, 960],
                [0, 0.6, 0],
                this.container_width
            );
            this.background_text = "中医智能健康大脑 ".repeat(
                parseInt(this.container_width * 0.045)
            );
            if (this.health_chart != null)
                this.health_chart.resize({ width: this.container_width });
            if (this.disease_chart != null)
                this.disease_chart.resize({ width: this.container_width });
        };
        window.onresize();
        this.getList();
        console.log(this.lists);
    },
};
</script>

<style scoped>
.text_container {
    min-height: auto;
    border-radius: 20px;
    position: relative;
    margin: 0px auto 10px;
    background: rgba(240, 240, 240, 0.8);
    overflow: auto;
    opacity: 0.8;
}
.mtb10 {
    margin: 5px 0;
}
.jishu {
    background: rgb(255, 255, 255) !important;
    opacity:none
}
</style>